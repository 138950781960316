// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-exchanges-tsx": () => import("./../../../src/pages/exchanges.tsx" /* webpackChunkName: "component---src-pages-exchanges-tsx" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-how-to-js": () => import("./../../../src/pages/how-to.js" /* webpackChunkName: "component---src-pages-how-to-js" */),
  "component---src-pages-ido-js": () => import("./../../../src/pages/ido.js" /* webpackChunkName: "component---src-pages-ido-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rewards-js": () => import("./../../../src/pages/rewards.js" /* webpackChunkName: "component---src-pages-rewards-js" */),
  "component---src-pages-roadmap-js": () => import("./../../../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-pages-swap-tsx": () => import("./../../../src/pages/swap.tsx" /* webpackChunkName: "component---src-pages-swap-tsx" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-template-js": () => import("./../../../src/pages/template.js" /* webpackChunkName: "component---src-pages-template-js" */),
  "component---src-pages-tokeltalk-js": () => import("./../../../src/pages/tokeltalk.js" /* webpackChunkName: "component---src-pages-tokeltalk-js" */)
}

